import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

export const geoSlice = createSlice({
  name: 'geo',
  initialState: {
    current: null
  },
  reducers: {
    setCurrentLocation: (state, action) => {
      state.current = action.payload
    }
  }
})

export const {setCurrentLocation} = geoSlice.actions

export default geoSlice.reducer
