// components/NavigationHandler.js
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {useDispatch} from 'react-redux';
import {restoreUserFromStorage} from "lib/features/authSlice";

const NavigationHandler = () => {
    const router = useRouter();
    const dispatch = useDispatch()

    useEffect(() => {
        const handleRouteChange = () => {
            // Your function to trigger on page navigation goes here
            console.log('Navigated to:', router.pathname);
            dispatch(restoreUserFromStorage())
        };

        // Add event listener for route changes
        router.events.on('routeChangeComplete', handleRouteChange);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);

    return null; // This component doesn't render anything visible
};

export default NavigationHandler;
