// This file configures MUI theme based on tailwind configuration

import resolveConfig from "tailwindcss/resolveConfig";
import {createTheme} from '@mui/material/styles'

const config = resolveConfig(require('./tailwind.config'))
// console.log(config)
const theme = createTheme({
  tonalOffset: 0.1,
  typography: {
    fontFamily: config.theme.fontFamily,
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      light: config.theme.colors.primary.DEFAULT,
      main: config.theme.colors.primary.dark,
      // dark: config.theme.colors.primary.dark,
    },
    black: {
      main: config.theme.colors.black.DEFAULT,
      contrastText: '#fff',
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      variants: [{
        props: {size: 'xl'},
        style: {
          padding: `${config.theme.spacing[3]} ${config.theme.spacing[7]}`
        }
      }]
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: config.theme.colors.primary.DEFAULT
        },
        circle: {
          strokeLinecap: 'round',
        },
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          color: config.theme.colors.primary.DEFAULT,
          backgroundColor: config.theme.colors.gray[200],
          borderRadius: '100px',
        },
        bar: {
          backgroundColor: config.theme.colors.primary.DEFAULT,
          borderRadius: '100px',
        }
      }
    }
  },
})

export default theme
