const BACKEND_ROOT_URL = "https://damamaps.online";
const API_ROOT_URL = `${BACKEND_ROOT_URL}/api/`;
const JWT_TOKEN = "damawish1234!";
const SEND_GRID_TOKEN = "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";
const POST_MARK_TOKEN = "21e39db3-ef26-4d28-a34b-ae2b5cf0734e";


module.exports = {
    BACKEND_ROOT_URL,
    API_ROOT_URL,
    JWT_TOKEN,
    SEND_GRID_TOKEN,
    POST_MARK_TOKEN
}


