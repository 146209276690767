import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getBrandById, getPaginatedBrands, getBrandsByType, getBrandsByKeyword, getTopBrands} from "lib/services/brand";


export const getBrand = createAsyncThunk(
    'brand/fetchBrandById',
    async ({slug, id}, {getState, rejectWithValue,fulfillWithValue}) => {
        console.log('at get brand slice: ', slug, id, typeof id)
        try {
            const {getBrandById:brand} = await getBrandById(id)
            console.log('brand slice result : ', brand)
            return fulfillWithValue(brand)
        } catch(err) {
            console.log('get brand failed: ', err)
            return rejectWithValue(err)
        }
    }
)

export const getBrandsByTypeThunk = createAsyncThunk(
    'brand/fetchBrandsByType',
    async ({type, sort}, {getState, rejectWithValue,fulfillWithValue}) => {
        const current = getState().brand.brands
        try {
            const {getBrandsByType:brands} = await getBrandsByType(type, sort)
            return fulfillWithValue(brands)
        } catch(err) {
            console.log('get brands by type failed: ', err)
            return rejectWithValue(err)
        }
    }
)

export const getPaginatedBrandsThunk = createAsyncThunk(
    'brand/getPaginatedBrands',
    async ({page, filter, sort}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const {getPaginatedBrands:brands} = await getPaginatedBrands()
            return fulfillWithValue(brands)
        } catch(err) {
            console.log('get paginated brands failed: ', err)
            return rejectWithValue(err)
        }
    }
)

export const searchBrandsByKeywordThunk = createAsyncThunk(
    'brand/getBrandsByKeyword',
    async ({searchKeyword}, {fulfillWithValue, rejectWithValue}) => {
        try {
            if (!!searchKeyword) {
                const {getBrandsByKeyword:brands} = await getBrandsByKeyword(searchKeyword)
                if (brands.length > 100) {
                    console.log('get brands res : ', brands.slice(brands.length - 1)[0].name)
                }
                return fulfillWithValue(brands)
            }
        } catch(err) {
            console.log('get brands by keyword thunk failed: ', err)
            return rejectWithValue(err)
        }
    }
)

export const getTopBrandsThunk = createAsyncThunk(
    'brand/getTopBrands',
    async ({num}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const {getTopBrands:brands} = await getTopBrands(num)
            return fulfillWithValue(brands)
        } catch(err) {
            console.log('get top brands failed: ', err)
            return rejectWithValue(err)
        }
    }
)

export const brandSlice = createSlice({
    name: 'brand',
    initialState: {
        brand: {
            id: 0,
            name: 'Test',
            businessType: 'STORE',
            slug: 'slug',
            products: []
        },
        brands: [{
            id: 0,
            name: 'Test',
            businessType: 'STORE',
            slug: 'slug',
            products: []
        }],
        brandSearchResult: [{
            id: 0,
            name: 'Test',
            businessType: 'STORE',
            slug: 'slug',
            products: []
        }],
        topBrands: [{
            id: 0,
            name: 'Test',
            businessType: 'STORE',
            slug: 'slug',
            products: []
        }]
    },
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(getBrand.fulfilled, (state, action) => {
            state.brand = action.payload
        })
        builder.addCase(getBrandsByTypeThunk.fulfilled, (state, action) => {
            state.brands = action.payload
        })
        builder.addCase(getPaginatedBrandsThunk.fulfilled, (state, action) => {
            if (action.payload.length > 100) {
                console.log('full fill brand : ', action.payload.slice(action.payload.length - 1)[0].name)
            }
            state.brands = action.payload.filter(brand => brand.name !== '' && brand.slug !== '')
        })
        builder.addCase(searchBrandsByKeywordThunk.fulfilled, (state, action) => {
            let res = action.payload.filter(brand => brand.name !== '' && brand.slug !== '')
            console.log('filtered search brands res: ', res)
            if (!!res) {
                state.brandSearchResult = res
            } else {
                state.brandSearchResult = []
            }
        })
        builder.addCase(getTopBrandsThunk.fulfilled, (state, action) => {
            state.topBrands = action.payload
        })
    }
})



export const {} = brandSlice.actions

export default brandSlice.reducer
