import {graph} from "lib/client";
import {gql} from "@apollo/client";
import _ from "lodash";

const brandCard = gql`
    fragment brandCard on Brand {
        id,
        name,
        slug,
        description,
        businessType,
        category,
        logo,
        hero,
        publicViewable,
        locations,
        products,
        services,
        liked,
        followers,
        bizType
    }
`

export async function getBrandById(id) {
    console.log('getting brand by id graph: ', id, typeof id)
    const {data} = await graph.query({
        query: gql`
            query GetBrandById($id: String!) {
                getBrandById(id: $id){
                    id,
                    name,
                    slug,
                    description,
                    logo,
                    hero,
                    businessType,
                    products,
                    locations,
                    services,
                    liked,
                    followers,
                    deals {
                        name, slug
                    }
                }
            }
        `,
        variables: {
            id
        }
    })
    console.log('data here: ', data)
    return data
}

export async function getBrandBySlug(slug) {
    const {data} = await graph.query({
        query: gql`
            query GetBrandBySlug($slug: String!) {
                getBrandBySlug(slug: $slug){
                    id,
                    name,
                    slug,
                    description,
                    logo,
                    hero,
                    businessType,
                    products,
                    locations,
                    services,
                    liked,
                    followers,
                    deals {
                        name, slug
                    }
                }
            }
        `,
        variables: {
            slug
        }
    })
    return data
}

export async function getFeaturedBrands() {
    const {data: {getFeaturedBrands}} = await graph.query({
        query: gql`
            ${brandCard}
            query {
                getFeaturedBrands {
                    ...brandCard
                }
            }
        `
    })

    return getFeaturedBrands
}

export async function getPaginatedBrands() {
    const {data} = await graph.query({
        query: gql`
            ${brandCard}
            query GetPaginatedBrands {
                getPaginatedBrands {
                    ...brandCard
                }
            }
        `
    })
    return data
}

export async function getBrandsByType(type, sort={}) {
    const {data} = await graph.query({
        query: gql`
            ${brandCard}
            query GetBrandsByType($type: BrandBusinessType!, $sort: BrandSort) {
                getBrandsByType(type: $type, sort: $sort) {
                    ...brandCard,
                }
            }
        `,
        variables: {
            type,
            sort
        }
    })
    return data
}

export async function getBrandsByProductCategory(slug) {
    const {data: {getBrandsByProductCategory: brands}} = await graph.query({
        query: gql`
            query GetBrandsByProductCategory($slug: String) {
                getBrandsByProductCategory(slug: $slug) {
                    name
                    slug
                }
            }
        `,
        variables: {
            slug,
        }
    })

    return brands
}

export async function getBrandsByKeyword(keyword) {
    const {data} = await graph.query({
        query: gql`
            ${brandCard}
            query GetBrandsByKeyword($keyword: String!) {
                getBrandsByKeyword(keyword: $keyword) {
                    ...brandCard
                }
            }
        `,
        variables: {
            keyword
        }
    })
    return data
}

export async function getTopBrands(num) {
    const {data} = await graph.query({
        query: gql`
            ${brandCard}
            query GetTopBrands($num: Int) {
                getTopBrands(num: $num) {
                    ...brandCard
                }
            }
        `,
        variables: {
            num
        }
    })
    return data
}
