import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getLocationById} from "lib/services/location";
import {api} from "lib/client";

export const getLocation = createAsyncThunk(
  'listing/fetchListingBySlug',
  async ({slug, id}, {getState, rejectWithValue,fulfillWithValue}) => {
    const current = getState().location.location
    console.log('current: ', current)
      const {getLocationById:location} = await getLocationById(id)
      return fulfillWithValue(location)
  }
)

export const bookAppointment = createAsyncThunk(
    'clinic/bookAppointment',
    async ({email, firstName, lastName, phone, time}, thunkAPI) => {
        return true
    }
)

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    location: {
      id: 0,
      rating: 5,
        location_type: 'STORE'
    }
  },
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.location = action.payload
    })
  }
})

export const {} = locationSlice.actions

export default locationSlice.reducer
