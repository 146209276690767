import {gql} from '@apollo/client'
import {graph} from 'lib/client'
import config from '../../data/config.json'

const ALL_DETAILS = gql`
    fragment allDetails on Product {
        id
        description
        name
        slug
        category
        strain_type
        price
        price_compare
        images
        brand {
            id
            name
            slug
        }
        location {
            distance
            slug
        }
        brand_verified
        rating
        reviews_count
        deal {
            name
            slug
            value
            unit
        }
        strain
        weight
        locations
        subCategory
    }
`

const SERVICE_DETAILS = gql`
  fragment serviceDetails on Service {
    id
    description
    name
    slug
    category 
    price
    images
    brand_id
    price_compare
    price_promo_from_dt
    price_promo_to_dt
    locations
  }
`

export async function getProductById({id}) {
    console.log('getting product by id query: ', id)
    const {data: {getProductById: product}} = await graph.query({
        query: gql`
            ${ALL_DETAILS}
            query GetProductById($id: String!) {
                getProductById(id: $id) {
                    ...allDetails
                }
            }
        `,
        variables: {
            id,
        }
    })
    return product
}

export async function getProductBySlug({slug}) {
  console.log('getting product query: ', slug)
  const {data: {getProductBySlug: product}} = await graph.query({
      query: gql`
          ${ALL_DETAILS}
          query GetProductBySlug($slug: String) {
              getProductBySlug(slug: $slug) {
                  ...allDetails
              }
          }
      `,
      variables: {
          slug,
      }
  })
  return product
}

export async function getPaginatedProducts() {
  const {data: {getPaginatedProducts: products}} = await graph.query({
    query: gql`
        ${ALL_DETAILS}
        query GetPaginatedProducts {
            getPaginatedProducts {
                ...allDetails
            }
        }
    `
  })
  return products
}

export async function getPaginatedServices() {
  const {data: {getPaginatedServices: services}} = await graph.query({
    query: gql`
        ${SERVICE_DETAILS}
        query GetPaginatedServices {
            getPaginatedServices {
                ...serviceDetails
            }
        }
    `
  })
  return services
}

export async function getServiceById({id}) {
    console.log('getting service query: ', id)
    const {data: {getServiceById: service}} = await graph.query({
        query: gql`
            ${SERVICE_DETAILS}
            query GetServiceById($id: String!) {
                getServiceById(id: $id) {
                    ...serviceDetails
                }
            }
        `,
        variables: {
            id,
        }
    })
    console.log('got service: ', service)
    return service
}

export async function getServiceBySlug({slug}) {
  console.log('getting service query: ', slug)
  const {data: {getServiceBySlug: service}} = await graph.query({
    query: gql`
      ${SERVICE_DETAILS}
      query GetServiceBySlug($slug: String) {
        getServiceBySlug(slug: $slug) {
          ...serviceDetails
        }
      }
    `,
    variables: {
      slug,
    }
  })
  console.log('got product: ', service)
  return service
}

export async function getProductCategories() {
  // const {data: {getProductCategories: categories}} = await graph.query({
  //   query: gql`
  //       query GetProductCategories {
  //           getProductCategories {
  //               name
  //               slug
  //               avatar_url
  //               subcategories {
  //                   name
  //                   slug
  //                   avatar_url
  //               }
  //           }
  //       }
  //   `
  // })

  return config.product.categories
}

export async function getServiceCategories() {
  // const {data: {getServiceCategories: categories}} = await graph.query({
  //   query: gql`
  //       query GetServiceCategories {
  //           getServiceCategories {
  //               name
  //               slug
  //               avatar_url
  //               subcategories {
  //                   name
  //                   slug
  //                   avatar_url
  //               }
  //           }
  //       }
  //   `
  // })

  return config.service.categories
}

export async function getProductsByKeyword(keyword) {
    console.log('at get products by keyword: ', keyword)
    const {data} = await graph.query({
        query: gql`
            ${ALL_DETAILS}
            query GetProductsByKeyword($keyword: String) {
                getProductsByKeyword(keyword: $keyword) {
                    ...allDetails
                }
            }
        `,
        variables: {
            keyword
        }
    })
    return data
}

export async function getServicesByKeyword(keyword) {
    const {data} = await graph.query({
        query: gql`
            ${SERVICE_DETAILS}
            query GetServicesByKeyword($keyword: String) {
                getServicesByKeyword(keyword: $keyword) {
                    ...serviceDetails
                }
            }
        `,
        variables: {
            keyword
        }
    })
    return data
}

export async function getTopProducts(num) {
    const {data: {getTopProducts: products}} = await graph.query({
        query: gql`
            ${ALL_DETAILS}
            query GetTopProducts($num: Int) {
                getTopProducts(num: $num) {
                    ...allDetails
                }
            }
        `,
        variables: {
            num
        }
    })
    return products
}

export async function getTopServices(num) {
    const {data: {getTopServices: services}} = await graph.query({
        query: gql`
            ${SERVICE_DETAILS}
            query GetTopServices($num: Int) {
                getTopServices(num: $num) {
                    ...serviceDetails
                }
            }
        `,
        variables: {
            num
        }
    })
    return services
}
