import {gql} from '@apollo/client'
import {graph} from "lib/client";
import _ from "lodash";

const allHours = gql`
    fragment allHours on BusinessHours {
        monday {
            open
            close
        }
        tuesday {
            open
            close
        }
        wednesday {
            open
            close
        }
        thursday {
            open
            close
        }
        friday {
            open
            close
        }
        saturday {
            open
            close
        }
        sunday {
            open
            close
        }
    }
`

const locationCard = gql`
    ${allHours}
    fragment locationCard on Location {
        id,
        avatar_url,
        name,
        slug,
        rating,
        reviews_count,
        distance,
        has_curbside_pickup,
        business_hours {
            ...allHours
        },
        timezone,
        location_type,
        brand_id,
        email,
        phone_number,
        amenities,
        license_type,
        products
    }
`

export async function getFeaturedLocations() {
  const {data: {getFeaturedLocations}} = await graph.query({
    query: gql`
        ${locationCard}
        query {
            getFeaturedLocations {
                ...locationCard,
                latitude,
                longitude,
                address,
                brand_id,
                social {
                    facebook, instagram, twitter, linkedin, youtube
                }
            }
        }
    `
  })

  return getFeaturedLocations
}

export async function getLocationById(id) {
    const {data} = await graph.query({
        query: gql`
            ${allHours}
            query GetLocationById($id: String!) {
                getLocationById(id: $id) {
                    id, name, slug, avatar_url, member_since,
                    followers_count, location_type,
                    address, zip_code, city, region, country,
                    timezone, latitude, longitude,
                    rating, reviews_count, address,
                    licenses {
                        type, number
                    }, license_type,
                    amenities, business_hours {
                        ...allHours
                    }, social {
                        facebook, instagram, twitter, linkedin, youtube
                    }, website, email, phone_number,
                    introduction, about, announcement,
                    brands {
                        name, slug
                    }, deals {
                        name, slug
                    }, license_type, products, services
                }
            }
        `,
        variables: {
            id
        }
    })
    return data
}

export async function getLocationBySlug(slug) {
  const {data} = await graph.query({
    query: gql`
        ${allHours}
        query GetLocationBySlug($slug: String!) {
            getLocationBySlug(slug: $slug) {
                id, name, slug, avatar_url, member_since,
                followers_count, location_type,
                address, zip_code, city, region, country,
                timezone, latitude, longitude,
                rating, reviews_count, address,
                licenses {
                    type, number
                }, license_type,
                amenities, business_hours {
                    ...allHours
                }, social {
                    facebook, instagram, twitter, linkedin, youtube
                }, website, email, phone_number,
                introduction, about, announcement,
                brands {
                    name, slug
                }, deals {
                    name, slug
                }, license_type
            }
        }
    `,
    variables: {
      slug
    }
  })
  return data
}

export async function getPaginatedLocations() {
  const {data} = await graph.query({
    query: gql`
        ${locationCard}
        query GetPaginatedLocations {
            getPaginatedLocations {
                ...locationCard,
                latitude,
                longitude,
                address,
                brand_id,
                social {
                    facebook, instagram, twitter, linkedin, youtube
                }
            }
        }
    `
  })
  return data
}

export async function getLocationsByKeyword(keyword) {
    const {data} = await graph.query({
        query: gql`
            ${locationCard}
            query GetLocationsByKeyword($keyword: String) {
                getLocationsByKeyword(keyword: $keyword) {
                    ...locationCard,
                    latitude,
                    longitude,
                    address,
                    brand_id,
                    social {
                        facebook, instagram, twitter, linkedin, youtube
                    }
                }
            }
        `,
        variables: {
            keyword
        }
    })
    return data
}

export async function getTopLocations(num) {
    const {data} = await graph.query({
        query: gql`
            ${locationCard}
            query GetTopLocations($num: Int) {
                getTopLocations(num: $num) {
                    ...locationCard,
                    latitude,
                    longitude,
                    address,
                    brand_id,
                    social {
                        facebook, instagram, twitter, linkedin, youtube
                    }
                }
            }
        `,
        variables: {
            num
        }
    })
    return data
}
