import {ApolloClient, InMemoryCache} from "@apollo/client";
import axios from "axios";

const { API_ROOT_URL } = require('../constants');
const remoteBaseURL = Boolean(process.env.NEXT_PUBLIC_IS_STAGING)
  ? 'https://qaq1luhv50.execute-api.us-east-1.amazonaws.com/dev'
  : 'https://s47k7w3fx0.execute-api.us-east-1.amazonaws.com/prod'

const baseURL = process.env.LOCAL_SERVER || process.env.NEXT_PUBLIC_LOCAL_SERVER
  ? 'http://localhost:8081/dev'
  : remoteBaseURL

export const graph = new ApolloClient({
  uri: (process.env.NEXT_PUBLIC_GQL_URL || `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`) + '/api/graphql',
  cache: new InMemoryCache({resultCaching: false}),
});

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL
})

export const backend_api = axios.create({
  baseURL: API_ROOT_URL
})
