import React, {Component, useEffect} from "react";
import '../styles/globals.scss'
import {Provider} from 'react-redux';

import {wrapper} from 'lib/store'

import {config} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {restoreUserFromStorage} from "lib/features/authSlice";
import {GlobalStyles} from "twin.macro";
import {ThemeProvider} from "@mui/material/styles";
import MuiTheme from '../theme/mui.config'
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import {setCurrentLocation} from "lib/features/geoSlice";
import {GoogleAnalytics} from "nextjs-google-analytics";
import {appWithTranslation} from 'next-i18next'
import NavigationHandler from '../components/NavigationHandler';

config.autoAddCss = false

const cache = createCache({
  key: 'css',
  prepend: true,
})

const MyApp = ({Component, ...rest}) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;
  const getLayout = Component.getLayout || ((page) => page)

  useEffect(() => {
    store.dispatch(restoreUserFromStorage())
  }, [])

  return (
        <Provider store={store}>
          <GoogleAnalytics />
          <CacheProvider value={cache}>
            <GlobalStyles/>
            <ThemeProvider theme={MuiTheme}>
              {/*<Component {...pageProps} />*/}
              <NavigationHandler />
              {getLayout(<Component {...pageProps} />)}
            </ThemeProvider>
          </CacheProvider>
        </Provider>
  )
}

MyApp.getStaticProps = wrapper.getStaticProps((store) => async ({router, ctx, locale}) => {
  const {
    country,
    region,
    city,
    languages,
    latitude, longitude,
  } = router.query
//
  store.dispatch(setCurrentLocation({
    country: new Intl.DisplayNames('en', {type: 'region'}).of(country),
    region,
    city,
    languages: languages ? languages.split(', ') : [],
    latitude, longitude,
  }))



  const {
    page = 1,
    locationTypes = [],
    amenities = [],
    openNow = false,
    sort = {},
  } = router.query
  const filter = {
    latitude: latitude,
    longitude: longitude,
    locationTypes,
    amenities,
    openNow
  }
})

export default appWithTranslation(MyApp)
