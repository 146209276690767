import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "lib/client";
import {isValidEmail} from "../../utils/helper";
import {brandSlice} from "lib/features/brandSlice";

export const loginWithEmail = createAsyncThunk(
  'auth/loginWithEmail',
  async ({email, password:pwd}, thunkAPI) => {
    const {data:user} = await api.post('/login', {email, pwd})
    localStorage.setItem('user', JSON.stringify({
      ...user,
      expire: Date.now() + 24 * 60 * 60 * 1000,
    }))
    return user
  }
)

export const restoreUserFromStorage = createAsyncThunk(
  'auth/restoreUserFromStorage',
  (_, {rejectWithValue,fulfillWithValue, dispatch}) => {
      console.log('====================  calling restoreUserFromStorage !!!!!!!!!')
    const user = JSON.parse(localStorage.getItem('user'))
    const requiredFields = ['email','expire','jwtToken','token','userid', 'dob']
    if (!!user && requiredFields.every(field => Object.keys(user).includes(field))) {
      if (user.expire > Date.now()) {
        return fulfillWithValue(user)
      }
    }
    dispatch(logout())
    return rejectWithValue(null)
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  (_, {fulfillWithValue}) => {
    localStorage.removeItem('user')
    // return fulfillWithValue()
  }
)

export const signUpWithEmail = createAsyncThunk(
  'auth/signUpWithEmail',
  async ({email, password:pwd, isBusinessUser, dob}, {fulfillWithValue, rejectWithValue}) => {
    return api.post('/sign-up', {email, pwd, isBusinessUser, dob})
      .then(r => {
        return fulfillWithValue(r.data)
      })
      .catch(e => {
        return rejectWithValue(e.response.data)
      })
  }
)

export const activateAccount = createAsyncThunk(
  'auth/activateAccount',
  async ({token}, {fulfillWithValue, rejectWithValue}) => {
    return api.post('/activation', {token})
      .then(r => {
        return fulfillWithValue(r.data.email)
      })
      .catch(e => {
        return rejectWithValue(e.response.data)
      })
  }
)

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({password:pwd}, {getState, fulfillWithValue, rejectWithValue}) => {
    const email = getState().auth.user?.email
    if (!email) return rejectWithValue()
    return api.post('/reset-password', {email, pwd})
      .then(() => fulfillWithValue())
      .catch(e => rejectWithValue(e.response))
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {
        name: '',
        email: '',
        phone: '',
        dob: ''
    },
      isLoggedIn: false
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(loginWithEmail.fulfilled, (state, action) => {
      state.user = {
        ...action.payload,
        isLoggedIn: true,
      }
    })
    builder.addCase(restoreUserFromStorage.fulfilled, (state, action) => {
        console.log('restoring: ', action)
        state.user = action.payload
        state.isLoggedIn = true

    })
    builder.addCase(restoreUserFromStorage.rejected, (state, action) => {
      state.user = {isLoggedIn: false}
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.user = {isLoggedIn: false}
    })
  }
})

export const {} = authSlice.actions
export default authSlice.reducer
