const defaultTheme = require('tailwindcss/defaultTheme')
module.exports = {
  // corePlugins: {
  //   preflight: false,
  // },
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./layouts/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: ['Montserrat', ...defaultTheme.fontFamily.sans],
        serif: ['DM Serif Display', ...defaultTheme.fontFamily.serif]
      },
      colors: {
        white: '#fff',
        black: {
          DEFAULT: '#333',
        },
        primary: {
          light: '',
          DEFAULT: '#18B8BF',
          dark: '#108085',
        },
        highlight: '#00AC9E',
        danger: '#f44336',
        success: '#4caf50',
      },
      borderWidth: {
        '3': '3px',
      },
      animation: {
        'spin-fast': 'spin 0.6s linear infinite'
      },
      keyframes: {

      }
    },
  },
  plugins: [],
}
