import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getLocationsByKeyword, getPaginatedLocations, getTopLocations} from "lib/services/location";

export const getPaginatedLocationsThunk = createAsyncThunk(
  'map/getPaginatedLocations',
  async ({page, filter, sort}, {fulfillWithValue, rejectWithValue}) => {
    let {getPaginatedLocations:locations} = await getPaginatedLocations(page, filter, sort).catch(e => {
      console.error(e)
    })
    locations = locations.filter(item => item.location_type !== '' && item.geoinfo !== null)
      return fulfillWithValue(locations)
  }
)

export const searchLocationByKeywordThunk = createAsyncThunk(
    'map/searchLocationByKeyword',
    async ({searchKeyword}, {fulfillWithValue, rejectWithValue}) => {
      try {
        if (!!searchKeyword) {
          const {getLocationsByKeyword:locations} = await getLocationsByKeyword(searchKeyword)
          if (locations.length > 100) {
            console.log('get locations res : ', locations.slice(locations.length - 1)[0].name)
          }
          return fulfillWithValue(locations)
        }
      } catch(err) {
        console.log('get locations by keyword thunk failed: ', err)
        return rejectWithValue(err)
      }
    }
)

export const getTopLocationsThunk = createAsyncThunk(
    'map/getTopLocations',
    async ({num}, {fulfillWithValue, rejectWithValue}) => {
      try {
        let {getTopLocations:locations} = await getTopLocations(num)
        if (!!locations) {
          locations = locations.filter(item => item.location_type !== '' && item.geoinfo !== null)
          return fulfillWithValue(locations)
        }
      } catch(err) {
        console.log('get top location thunk failed: ', err)
      }
    }
)

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    locations: [{
      rating: 5,
      location_type: 'STORE',
      distance: 0,
      id: 0,
      avatar_url: '',
      name: '',
      slug: '',
      reviews_count: '',
      has_curbside_pickup: '',
      business_hours: {
        monday: {
          open: '',
          close: ''
        },
        tuesday: {
          open: '',
          close: ''
        },
        wednesday: {
          open: '',
          close: ''
        },
        thursday: {
          open: '',
          close: ''
        },
        friday: {
          open: '',
          close: ''
        },
        saturday: {
          open: '',
          close: ''
        },
        sunday: {
          open: '',
          close: ''
        },
      },
      timezone: 'GMT',
      brand_id: '',
      email: '',
      phone_number: '',
      amenities: '',
      license_type: '',
      latitude: '',
      longitude: '',
      address: '',
    }],
    locationSearchResult: [{
      rating: 5,
      location_type: 'STORE',
      distance: 0,
      id: 0,
      avatar_url: '',
      name: '',
      slug: '',
      reviews_count: '',
      has_curbside_pickup: '',
      business_hours: {
        monday: {
          open: '',
          close: ''
        },
        tuesday: {
          open: '',
          close: ''
        },
        wednesday: {
          open: '',
          close: ''
        },
        thursday: {
          open: '',
          close: ''
        },
        friday: {
          open: '',
          close: ''
        },
        saturday: {
          open: '',
          close: ''
        },
        sunday: {
          open: '',
          close: ''
        },
      },
      timezone: 'GMT',
      brand_id: '',
      email: '',
      phone_number: '',
      amenities: '',
      license_type: '',
      latitude: '',
      longitude: '',
      address: '',
    }],
    topLocations: [{
      rating: 5,
      location_type: 'STORE',
      distance: 0,
      id: 0,
      avatar_url: '',
      name: '',
      slug: '',
      reviews_count: '',
      has_curbside_pickup: '',
      business_hours: {
        monday: {
          open: '',
          close: ''
        },
        tuesday: {
          open: '',
          close: ''
        },
        wednesday: {
          open: '',
          close: ''
        },
        thursday: {
          open: '',
          close: ''
        },
        friday: {
          open: '',
          close: ''
        },
        saturday: {
          open: '',
          close: ''
        },
        sunday: {
          open: '',
          close: ''
        },
      },
      timezone: 'GMT',
      brand_id: '',
      email: '',
      phone_number: '',
      amenities: '',
      license_type: '',
      latitude: '',
      longitude: '',
      address: '',
    }],
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getPaginatedLocationsThunk.fulfilled, (state, action) => {
      state.locations = action.payload
    }),
    builder.addCase(searchLocationByKeywordThunk.fulfilled, (state, action) => {
      state.locationSearchResult = action.payload
    })
    builder.addCase(getTopLocationsThunk.fulfilled, (state, action) => {
      state.topLocations = action.payload
    })
  }
})

export const {} = mapSlice.actions

export default mapSlice.reducer
